import {Component, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import {DepartmentService} from "../../../services/department.service";
import {DepartmentDTO} from "../interface/department.entity";
import {CreateDepartmentComponent} from "../create-department/create-department.component";
import {RouterLink} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CreateCategoryComponent} from "../../category";
import {CreateEmployeeComponent} from "../../employee";
import {NgClass} from "@angular/common";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {DotLoadingServices} from "../../../../core";

@Component({
  selector: 'app-department-grid',
  standalone: true,
  imports: [
    CreateDepartmentComponent,
    RouterLink,
    ReactiveFormsModule,
    CreateEmployeeComponent,
    CreateCategoryComponent,
    NgClass,
    FormsModule,
    PaginationComponent
  ],
  templateUrl: './department-grid.component.html',
  styleUrl: './department-grid.component.scss'
})
export class DepartmentGridComponent {
  department: DepartmentDTO[] = []

  @Input() showModal = signal(false);
  @Output() showModalChange = new EventEmitter<boolean>();

  departmentService = inject(DepartmentService)
  dotLoadingServices = inject(DotLoadingServices)

  searchParams = {
    department_code: '',
    department_status: '',
    page_number: 1,
    items_per_page: 10
  }

  totalItems = 0;
  itemsPerPage = 10;

  constructor() {

  }

  onClick(id: number) {
    this.departmentService.getById(id.toString(), true).subscribe(
      (error) => {
        console.error('Error fetching departments:', error);
      }
    );
  }

  search() {
    this.dotLoadingServices.setLoading(true);
    this.departmentService.find(this.searchParams, true).subscribe(
      (response) => {
        if (response.data.totalItems == 0) {
        }
        this.dotLoadingServices.setLoading(false);
        this.totalItems = response.data.totalItems
        this.itemsPerPage = response.data.itemsPerPage
        const dep = response.data.data
        this.department.push(...dep)
      }, error => {
        this.dotLoadingServices.setLoading(false);
        console.error('Error fetching departments:', error);
        this.dotLoadingServices.setLoading(false);
      }
    );
  }

  showCreateDepartment = signal(false);

  onPageChange(pageNumber: number) {
    this.searchParams = {
      ...this.searchParams,
      page_number: pageNumber
    };
    this.search();
  }

  openDepCreateDepartment() {
    this.showCreateDepartment.set(true);
  }

  onShowDepModalChange(show: boolean) {
    this.showCreateDepartment.set(show);
  }

  clearSearch() {
    this.searchParams = {
      department_code: '',
      department_status: '',
      page_number: 1,
      items_per_page: 10
    };
    const searchInput = document.getElementById('table-search') as HTMLInputElement;
    if (searchInput) {
      searchInput.value = '';
    }
    const statusSelect = document.getElementById('type') as HTMLSelectElement;
    if (statusSelect) {
      statusSelect.value = '';
    }
    this.search();
  }
}
