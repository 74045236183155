import {HttpClient,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CachedAPIRequest, PaginationResponse} from "../../core";
import {BehaviorSubject, find, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {Employee, EmployeeDataDTO,} from "../feature/employee/interface/employee.entity";
import {APIRequestResources} from "../../core";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends CachedAPIRequest {

  $all = new BehaviorSubject<EmployeeDataDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<Employee | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.EmployeeService);
    this.find({
      code: '',
      employeeStatus: '',
      pageNumber: 1,
      itemsPerPage: 10
    }, true).pipe(take(1)).subscribe()

  }

  create = (employee: any) => {
    return this.post<any>(employee, {}).pipe(
      tap(() => {
        this.$all.next([])
      })
    );
  }

  getById = (id: string, refresh = true) => {
    return this.get<Employee>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  update = (employeeId: number, employeeDetails: any) => {
    const options = {suffix: employeeId.toString()};
    return this.put<any>(employeeDetails, options).pipe(
      tap(() => {
      })
    );
  }

  initial() {
    this.$active.next(undefined);
  }

  find(searchParams: any, refresh = true) {
    return this.get<PaginationResponse<EmployeeDataDTO[]>>({
      endpoint: `find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => {
          this.$all.next(res.data.data);
        })
      );
  }
}
