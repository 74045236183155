import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {AuthState, UserPermission} from "../types";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL = `${environment.API_BASE}/auth/login`;
  private readonly PORTAL_URL = environment.PORTAL;

  private authState = new BehaviorSubject<AuthState>({
    isAuthenticated: false,
    username: '',
    permissions: [],
    accessToken: null,
    refreshToken: null,
  });


  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.initAuthState();
  }

  signIn(authKey: string): Observable<boolean> {
    const headers = new HttpHeaders().set('auth-key', authKey);

    return this.http.post<any>(this.API_URL, {}, { headers }).pipe(
      map(response => this.handleSignInResponse(response)),
      catchError(error => this.handleSignInError(error))
    );
  }

  signOut(): void {
    this.clearAuthData();
    window.location.href = this.PORTAL_URL;
  }

  getAuthState(): Observable<AuthState> {
    return this.authState.asObservable();
  }

  hasPermission(requiredPath: string, requiredAction: string, status?: string): boolean {
    const currentState = this.authState.value;

    if (!currentState.isAuthenticated) {
      return false;
    }

    const relevantPermission = currentState.permissions.find(
      perm => perm.resourcePath === requiredPath
    );

    if (!relevantPermission) {
      return false;
    }

    return relevantPermission.context.includes(requiredAction) ||
      relevantPermission.context.includes('all');
  }

  private initAuthState(): void {
    const savedState = sessionStorage.getItem('auth-state');
    if (savedState) {
      try {
        const parsedState = JSON.parse(savedState);
        this.updateAuthState(parsedState);
      } catch (error) {
        console.error('Error parsing saved auth state:', error);
        this.clearAuthData();
      }
    }
  }

  private handleSignInResponse(response: any): boolean {
    if (!response?.data?.accessToken) {
      return false;
    }

    const { accessToken, refreshToken, username, userPermissions } = response.data;
    const parsedPermissions = this.parseUserPermissions(userPermissions);

    const newState: AuthState = {
      isAuthenticated: true,
      username,
      permissions: parsedPermissions,
      accessToken,
      refreshToken,
    };

    this.updateAuthState(newState);
    return true;
  }

  private handleSignInError(error: any): Observable<boolean> {
    console.error('Sign-in error:', error);
    this.clearAuthData();
    return of(false);
  }

  private updateAuthState(state: AuthState): void {
    this.authState.next(state);
    sessionStorage.setItem('auth-state', JSON.stringify(state));
  }

  private parseUserPermissions(permissions: any): UserPermission[] {
    if (typeof permissions === 'string') {
      try {
        permissions = JSON.parse(permissions);
      } catch (error) {
        console.error('Error parsing permissions:', error);
        return [];
      }
    }

    return Array.isArray(permissions) ? permissions.map(perm => ({
      ...perm,
      context: Array.isArray(perm.context) ? perm.context : perm.context.split(',')
    })) : [];
  }

  private clearAuthData(): void {
    const emptyState: AuthState = {
      isAuthenticated: false,
      username: '',
      permissions: [],
      accessToken: null,
      refreshToken: null,
    };
    this.updateAuthState(emptyState);
    sessionStorage.removeItem('auth-state');
  }
}
