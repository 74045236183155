import { Component, inject, AfterViewInit, signal, effect, Input, Output, EventEmitter } from '@angular/core';
import { Category } from "../../employee/interface/employee.entity";
import { FormsModule } from "@angular/forms";
import { APIResponse, DotLoadingServices, NotificationService } from "../../../../core";
import { CategoryService } from "../../../services/category.service";
import { Modal } from "flowbite";
import { NgClass, NgIf } from "@angular/common";
import { CategoryDTO } from "../interface/category.entity";
import { take } from "rxjs";

@Component({
  selector: 'app-create-category',
  standalone: true,
  imports: [FormsModule, NgIf, NgClass],
  templateUrl: './create-category.component.html',
  styleUrl: './create-category.component.scss'
})
export class CreateCategoryComponent implements AfterViewInit {
  @Input() showModal = signal(false);
  @Output() showModalChange = new EventEmitter<boolean>();
  private modal: Modal | null = null;

  categoryId = signal<number>(-1);
  categoryService = inject(CategoryService);

  categoryDTO = {
    code: '',
    name: '',
    level: '',
    status: ''
  };

  constructor(
    private notificationService: NotificationService,
    private dotLoadingServices: DotLoadingServices,
  ) {
    effect(() => {
      const cate = this.categoryService.active();
      if (cate) {
        this.categoryId.set(cate.id);
        this.categoryDTO = { ...cate };
      }
    }, { allowSignalWrites: true });

    effect(() => {
      if (this.showModal()) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }, { allowSignalWrites: true });
  }

  submit() {
    this.dotLoadingServices.setLoading(true);
    const isUpdate = this.categoryId() > 0;
    const action = isUpdate ? 'update' : 'create';

    const request$ = isUpdate
      ? this.categoryService.update(this.categoryId(), this.categoryDTO)
      : this.categoryService.create(this.categoryDTO);

    request$.pipe(take(1)).subscribe({
      next: (response: APIResponse<Category>) => {
        const successAction = isUpdate ? 'updated' : 'created';
        this.notificationService.showNotification({
          type: 'success',
          message: `Category ${successAction} successfully`,
        });
        this.dotLoadingServices.setLoading(false);
        this.modal?.hide();

        if (!isUpdate) {
          this.refreshCategoryList();
        }
      },
      error: (error) => {
        let errorMessage = `An unexpected error occurred while ${action}ing the category`
        switch (error.status) {
          case 409:
            errorMessage = error.error.message || `A category with this ${isUpdate ? 'new ' : ''}code or name already exists`;
            break;
          case 404:
            errorMessage = `Category not found. It may have been deleted`;
            break;
          case 400:
            errorMessage = error.error.message || `Invalid category data provided`;
            break;
          case 403:
            errorMessage = `You don't have permission to ${action} categories`;
            break;
        }

        this.notificationService.showNotification({
          type: 'error',
          message: errorMessage,
        });

        console.error(`Error ${action}ing category:`, error);
        this.dotLoadingServices.setLoading(false);
      }
    });
  }

  private refreshCategoryList() {
    this.categoryService.find({
      employee_category_status: '',
      employee_category_level: '',
      page_number: 1,
      items_per_page: 10,
    }, true).pipe(take(1)).subscribe();
  }

  ngAfterViewInit(): void {
    this.initializeModal();
    this.categoryService.initial();
  }

  private initializeModal(): void {
    const modalElement = document.getElementById('crud-modal');
    if (modalElement) {
      this.modal = new Modal(modalElement, {
        placement: 'center',
        backdrop: 'static',
        closable: true,
        onHide: () => {
          this.showModal.set(false);
          this.showModalChange.emit(false);
        }
      });
    }
  }

  openModal(): void {
    this.modal?.show();
    this.resetForm();
  }

  closeModal(): void {
    this.modal?.hide();
    this.categoryService.initial();
  }

  validateForm(): boolean {
    return Boolean(this.categoryDTO.name && this.categoryDTO.code && this.categoryDTO.level && this.categoryDTO.status);
  }

  resetForm(): void {
    this.categoryId.set(-1);
    this.categoryDTO = {
      code: '',
      name: '',
      level: '',
      status: ''
    };
    this.categoryService.initial();
  }

  toggleDepModal(show: boolean): void {
    this.showModal.set(show);
    this.showModalChange.emit(show);
  }
}
