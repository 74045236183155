import {
  AfterViewInit,
  Component,
  createComponent,
  effect,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";
import {DepartmentDTO} from "../../department/interface/department.entity";
import {CategoryDTO} from "../../category/interface/category.entity";
import {APIResponse, DotLoadingServices, NotificationService} from "../../../../core";
import {Category, Employee, EmployeeDataDTO} from "../interface/employee.entity";
import {EmployeeService} from "../../../services/employee.service";
import {DepartmentService} from "../../../services/department.service";
import {CategoryService} from "../../../services/category.service";
import {format, isValid, parseISO} from "date-fns";
import {Modal} from "flowbite";
import {catchError, EMPTY, finalize, Subject, take, takeUntil} from "rxjs";


@Component({
  selector: 'app-create-employee',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgClass
  ],
  templateUrl: './create-employee.component.html',
  styleUrl: './create-employee.component.scss'
})
export class CreateEmployeeComponent implements  AfterViewInit, OnDestroy {
  @Input() showEmpModal = signal(false);
  @Output() showEmpModalChange = new EventEmitter<boolean>();

  private modal: Modal | null = null;

  employee: EmployeeDataDTO[] = [];
  category: CategoryDTO[] = []
  department: DepartmentDTO[] = []
  private unsubscribe$ = new Subject<void>();
  today = format(new Date(), 'yyyy-MM-dd');

  employeeId = signal(-1)

  employeeService = inject(EmployeeService)
  departmentService = inject(DepartmentService)
  categoryService = inject(CategoryService)

  searchParams = {
    employee_category_code:'',
    employee_category_status:'',
    employee_category_level:'',
    page_number: 1,
    items_per_page: 10
  }

  employeeDTO = {
    code: '',
    fullName: '',
    displayName: '',
    email: '',
    nic: '',
    contactNumber: '',
    gender: '',
    department: 0,
    category: 0,
    joinDate: '',
    employeeStatus: '',
    level: ''
  };


  constructor(private notificationService: NotificationService, private dotLoadingServices: DotLoadingServices, private employeeService1: EmployeeService ) {
    effect(() => {
      const employee = this.employeeService.active()
      if (employee) {
        this.employeeId.set(employee.id)
        this.employeeDTO = {
          ...employee,
          department: Number(employee.department.id),
          category: Number(employee.category.id),
          level: employee.category.level,
          joinDate: format(parseISO(employee?.joinDate), 'yyyy-MM-dd')
        }
      }
    }, {allowSignalWrites: true});
    effect(() => {
      if (this.showEmpModal()) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },{allowSignalWrites:true});
  }

  protected readonly status = status;

  submit() {
      this.dotLoadingServices.setLoading(true);
      this.employeeDTO = {
        ...this.employeeDTO,
        department: Number(this.employeeDTO.department),
        category: Number(this.employeeDTO.category),
        contactNumber: String(this.employeeDTO.contactNumber),
      };

      const isUpdate = this.employeeId() > 0;
      const action = isUpdate ? 'update' : 'create';
      const operation = isUpdate
        ? this.employeeService.update(this.employeeId(), this.employeeDTO)
        : this.employeeService.create(this.employeeDTO);

      operation.pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.dotLoadingServices.setLoading(false))
      ).subscribe({
        next: (response: APIResponse<Employee>) => {
          this.notificationService.showNotification({
            type: 'success',
            message: `Employee ${action}d successfully`,
          });
          this.modal?.hide();

          if (!isUpdate) {
            this.refreshEmployeeList();
          }
        },
        error: (error) => {
          let errorMessage = `An unexpected error occurred while ${action}ing the employee`;
          switch (error.status) {
            case 409:
              if (error.error.message.includes('email')) {
                errorMessage = `An employee with this ${isUpdate ? 'new ' : ''}email already exists`;
              } else if (error.error.message.includes('code')) {
                errorMessage = `An employee with this ${isUpdate ? 'new ' : ''}employee code already exists`;
              } else {
                errorMessage = error.error.message || `An employee with this ${isUpdate ? 'new ' : ''}information already exists`;
              }
              break;
            case 404:
              errorMessage = isUpdate
                ? `Employee not found. It may have been deleted`
                : `Department or employee category not found`;
              break;
            case 400:
              errorMessage = error.error.message || `Invalid employee data provided`;
              break;
            case 403:
              errorMessage = `You don't have permission to ${action} employees`;
              break;
          }
          this.notificationService.showNotification({
            type: 'error',
            message: errorMessage,
          });
          console.error(`Error ${action}ing employee:`, error);
        }
      });

  }

  private refreshEmployeeList() {
    this.employeeService.find({
      employeeStatus: '',
      level: '',
      pageNumber: 1,
      itemsPerPage: 10,
    }, true).pipe(
      take(1),
      catchError(error => {
        console.error('Error refreshing employee list', error);
        return EMPTY;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.initializeModal();
    this.employeeService.initial()
  }


  private initializeModal(): void {
    const modalElement = document.getElementById('crud-modal');
    if (modalElement) {
      this.modal = new Modal(modalElement, {
        placement: 'center',
        backdrop: 'static',
        closable: true,
        onHide: () => {
          this.showEmpModal.set(false);
          this.showEmpModalChange.emit(false);
        }
      });
    }
  }

  openModal(): void {
    this.modal?.show();
    this.resetForm();
  }

  closeModal(): void {
    this.modal?.hide();
    this.employeeService.initial()
  }

  validateForm(): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const nicRegex = /^([0-9]{9}[vV]|[0-9]{12})$/;
    const phoneRegex = /^07[0-9]{8}$/;

    return !this.employeeDTO.code ||
      !this.employeeDTO.fullName ||
      !this.employeeDTO.displayName ||
      !this.employeeDTO.nic || !nicRegex.test(this.employeeDTO.nic) ||
      !this.employeeDTO.email || !emailRegex.test(this.employeeDTO.email) ||
      !this.employeeDTO.contactNumber || !phoneRegex.test(this.employeeDTO.contactNumber) ||
      !this.employeeDTO.gender ||
      !this.employeeDTO.department ||
      !this.employeeDTO.category ||
      !this.employeeDTO.joinDate ||
      !this.employeeDTO.employeeStatus ||
      !this.employeeDTO.level;
  }

  resetForm(): void {
    this.employeeId.set(0);
    this.employeeDTO = {
      code: '',
      displayName: '',
      fullName: '',
      nic: '',
      email: '',
      contactNumber: '',
      gender: '',
      department: 0,
      category: 0,
      joinDate: '',
      employeeStatus: '',
      level: ''
    }
  }

  toggleModal(show: boolean): void {
    this.showEmpModal.set(show);
    this.showEmpModalChange.emit(show);
  }
}
