import {Component, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {CategoryDTO} from "../interface/category.entity";
import {CreateCategoryComponent} from "../create-category/create-category.component";
import {FormsModule} from "@angular/forms";
import {CategoryService} from "../../../services/category.service";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {DotLoadingServices} from "../../../../core";

@Component({
  selector: 'app-category-grid',
  standalone: true,
  imports: [
    NgForOf,
    CreateCategoryComponent,
    FormsModule,
    NgClass,
    NgIf,
    PaginationComponent
  ],
  templateUrl: './category-grid.component.html',
  styleUrl: './category-grid.component.scss'
})
export class CategoryGridComponent implements OnInit{
  category: CategoryDTO[] = []


  @Input() showModal = signal(false);
  @Output() showModalChange = new EventEmitter<boolean>();

  categoryService = inject(CategoryService)
  dotLoadingServices = inject(DotLoadingServices)

  searchParams = {
    employee_category_code:'',
    employee_category_status:'',
    employee_category_level:'',
    page_number: 1,
    items_per_page: 10
  }

  totalItems = 0;
  itemsPerPage = 10;

  constructor() {
  }

  ngOnInit() {
  }

  onClick(id: number) {
    this.categoryService.getById(id.toString(), true).subscribe(
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  search() {
    this.dotLoadingServices.setLoading(true);
    this.categoryService.find(this.searchParams, true).subscribe(
      (response) => {
        this.totalItems = response.data.totalItems
        this.itemsPerPage = response.data.itemsPerPage
        const cate=response.data.data
        this.category.push(...cate)
        this.dotLoadingServices.setLoading(false);
      },
      (error) => {
        console.error('Error fetching categories:', error);
        this.dotLoadingServices.setLoading(false);
      }
    );
  }

  showCreateCategory = signal(false);

  onPageChange(pageNumber: number) {
    this.searchParams = {
      ...this.searchParams,
      page_number: pageNumber
    };
    this.search();
  }

  openCreateCategory() {
    this.showCreateCategory.set(true);
  }

  onShowModalChange(show: boolean) {
    this.showCreateCategory.set(show);
  }

  clearSearch() {
    this.searchParams = {
      employee_category_code: '',
      employee_category_status: '',
      employee_category_level: '',
      page_number: 1,
      items_per_page: 10
    };
    const searchInput = document.getElementById('table-search') as HTMLInputElement;
    if (searchInput) {
      searchInput.value = '';
    }
    const statusSelect = document.getElementById('type') as HTMLSelectElement;
    if (statusSelect) {
      statusSelect.value = '';
    }
    this.search();
  }
}
