import {AfterViewInit, Component, effect, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Category, Department} from "../../employee/interface/employee.entity";
import {APIResponse, DotLoadingServices, NotificationService} from "../../../../core";
import {DepartmentService} from "../../../services/department.service";
import {Modal} from "flowbite";
import {DepartmentDTO} from "../interface/department.entity";
import {take} from "rxjs";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {NgClass} from "@angular/common";


@Component({
  selector: 'app-create-department',
  standalone: true,
  imports: [
    FormsModule,
    NgClass
  ],
  templateUrl: './create-department.component.html',
  styleUrl: './create-department.component.scss'
})
export class CreateDepartmentComponent implements AfterViewInit {
  @Input() showDepModal = signal(false);
  @Output() showDepModalChange = new EventEmitter<boolean>();

  department: DepartmentDTO[] = [];

  departmentId = signal(-1)
  departmentService = inject(DepartmentService)
  departmentDTO = {
    code: '',
    name: '',
    departmentStatus: ''
  };
  private depModal: Modal | null = null;

  constructor(private notificationService: NotificationService, private dotLoadingServices: DotLoadingServices,) {

    effect(() => {
      const dep = this.departmentService.active();

      if (dep) {
        this.departmentId.set(dep.id)
        this.departmentDTO.code = dep?.code,
          this.departmentDTO.name = dep?.name,
          this.departmentDTO.departmentStatus = dep?.departmentStatus
      }
    }, {allowSignalWrites: true});

    effect(() => {
      if (this.showDepModal()) {
        this.openDepModal();
      } else {
        this.closeDepModal();
      }
    }, {allowSignalWrites: true});
  }

  submit() {
    this.dotLoadingServices.setLoading(true);
    const isUpdate = this.departmentId() > 0;
    const action = isUpdate ? 'update' : 'create';

    const request$ = isUpdate
      ? this.departmentService.update(this.departmentId(), this.departmentDTO)
      : this.departmentService.create(this.departmentDTO);

    request$.pipe(take(1)).subscribe({
      next: (response: APIResponse<Department>) => {
        const successAction = isUpdate ? 'updated' : 'created';
        this.notificationService.showNotification({
          type: 'success',
          message: `Department ${successAction} successfully`,
        });
        this.dotLoadingServices.setLoading(false);
        this.depModal?.hide();

        if (!isUpdate) {
          this.refreshDepartmentList();
        }
      },
      error: (error) => {
        let errorMessage = `An unexpected error occurred while ${action}ing the department`;

        switch (error.status) {
          case 409:
            errorMessage = error.error.message || `A department with this ${isUpdate ? 'new ' : ''}code or name already exists`;
            break;
          case 404:
            errorMessage = `Department not found. It may have been deleted`;
            break;
          case 400:
            errorMessage = error.error.message || `Invalid department data provided`;
            break;
          case 403:
            errorMessage = `You don't have permission to ${action} departments`;
            break;
        }

        this.notificationService.showNotification({
          type: 'error',
          message: errorMessage,
        });

        console.error(`Error ${action}ing department:`, error);
        this.dotLoadingServices.setLoading(false);
      }
    });
  }

  private refreshDepartmentList() {
    this.departmentService.find({
      code: '',
      name: '',
      departmentStatus: '',
      page_number: 1,
      items_per_page: 10,
    }, true).pipe(take(1)).subscribe();
  }

  ngAfterViewInit(): void {
    this.initializeDepModal();
    this.departmentService.initial()
  }


  private initializeDepModal(): void {
    const modalElement = document.getElementById('dep-crud-modal');
    if (modalElement) {
      this.depModal = new Modal(modalElement, {
        placement: 'center',
        backdrop: 'static',
        closable: true,
        onHide: () => {
          this.showDepModal.set(false);
          this.showDepModalChange.emit(false);
        }
      });
    }
  }

  openDepModal(): void {
    this.depModal?.show();
    this.resetForm();
  }

  closeDepModal(): void {
    this.depModal?.hide();
    this.departmentService.initial()
  }

  validateForm() {
    return !this.departmentDTO.code || !this.departmentDTO.name || !this.departmentDTO.departmentStatus;
  }


    resetForm(): void {
    this.departmentId.set(0);
    this.departmentDTO = {
      code: '',
      name: '',
      departmentStatus: ''
    };
    this.departmentService.initial()
  }

  toggleDepModal(show: boolean): void {
    this.showDepModal.set(show);
    this.showDepModalChange.emit(show);
  }
}
