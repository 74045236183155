import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {take, tap} from 'rxjs'
import {Department, DepartmentDTO, UpdateDepartment} from "../feature/department/interface/department.entity";
import {toSignal} from "@angular/core/rxjs-interop";
import {PaginationResponse} from "../../core";
import {EmployeeDataDTO} from "../feature/employee/interface/employee.entity";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends CachedAPIRequest {

  $all = new BehaviorSubject<DepartmentDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<Department | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.DepartmentService);
    this.find({
      department_code: '',
      department_name: '',
      department_status: '',
      page_number: 1,
      items_per_page: 10
      }, true).pipe(take(1)).subscribe();
  }


  create = (department: any) => {
    return this.post<any>(department, {}).pipe(
      tap(() => {
        this.$all.next([])
      })
    );
  }

  getById = (id: string, refresh= true) => {
    return this.get<Department>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )

  }

  update = (depId: number, departmentDetails: any) => {
    const options = {suffix: depId.toString()};
    return this.put<any>(departmentDetails, options).pipe(
      tap(() => {
      })
    );
  }

  initial(){
    this.$active.next(undefined)
  }

  find(searchParams: any, refresh = true) {
    return this.get<PaginationResponse<DepartmentDTO[]>>({
      endpoint: `find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => {
          this.$all.next(res.data.data);
        })
      );
  }
}
