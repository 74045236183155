<div id="dep-crud-modal" tabindex="-1" aria-hidden="true"
     class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

  <div class="relative p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow">

      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
        <h3 class="text-lg font-semibold text-gray-900">
          {{ departmentId() > 0 ? 'Update' : 'Create New' }} Department
        </h3>
        <button type="button" (click)="toggleDepModal(false)"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>

      <div class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-2">

          <div class="col-span-2">
            <label for="Department.code" class="block mb-2 text-sm font-medium text-gray-900">Department Code <span class="text-red-700">*</span></label>
            <div class="relative">
              <input type="text" id="Department.code" class="floating-btn peer" [(ngModel)]="departmentDTO.code" placeholder=" "/>
            </div>
          </div>

          <div class="col-span-2 sm:col-span-1">
            <label for="Department.name" class="block mb-2 text-sm font-medium text-gray-900">Department Name <span class="text-red-700">*</span></label>
            <div class="relative">
              <input type="text" id="Department.name" class="floating-btn peer" [(ngModel)]="departmentDTO.name"/>
            </div>
          </div>

          <div class="col-span-2 sm:col-span-1">
            <label for="category.status" class="block mb-2 text-sm font-medium text-gray-900">Department Status <span class="text-red-700">*</span></label>
            <select id="category.status" [(ngModel)]="departmentDTO.departmentStatus"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
              <option hidden selected="">Choose Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="pending">Pending</option>
              <option value="deleted">Deleted</option>
            </select>
          </div>
        </div>
      </div>

      <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
        <button type="submit" [disabled]="validateForm()" [ngClass]="{'button-blue': !validateForm(), 'button-disabled': validateForm()}" (click)="submit()">
          {{departmentId()>0 ? 'Update' : 'Create New' }} Department
        </button>
      </div>

    </div>
  </div>
</div>
