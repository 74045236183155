import {Component, effect, EventEmitter, inject, Injector, Input, OnInit, Output, signal} from '@angular/core';
import {EmployeeDataDTO, EmployeeList} from "../interface/employee.entity";
import {CreateEmployeeComponent} from "../create-employee/create-employee.component";
import {EmployeeService} from "../../../services/employee.service";
import {NgClass} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CreateCategoryComponent} from "../../category";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {DotLoadingServices} from "../../../../core";

@Component({
  selector: 'app-employee-grid',
  standalone: true,
  imports: [
    CreateEmployeeComponent,
    NgClass,
    ReactiveFormsModule,
    CreateCategoryComponent,
    FormsModule,
    PaginationComponent
  ],
  templateUrl: './employee-grid.component.html',
  styleUrl: './employee-grid.component.scss'
})
export class EmployeeGridComponent {
  employee: EmployeeDataDTO[] = []

  @Input() showModal = signal(false);
  @Output() showModalChange = new EventEmitter<boolean>();

  employeeService = inject(EmployeeService)
  dotLoadingServices = inject(DotLoadingServices)

  searchParams = {
    code: '',
    employeeStatus: '',
    pageNumber: 1,
    itemsPerPage: 10
  }

  totalItems = 0;
  itemsPerPage = 10;

  onClick(id: number) {
    this.employeeService.getById(id.toString(), true).subscribe(
      (error) => {
        console.error('Error fetching employees:', error);
      }
    );
  }

  search() {
    this.dotLoadingServices.setLoading(true);
    this.employeeService.find(this.searchParams, true).subscribe(
      (response) => {
        this.totalItems = response.data.totalItems
        this.itemsPerPage = response.data.itemsPerPage
        const emp = response.data.data
        this.employee.push(...emp)
        this.dotLoadingServices.setLoading(false);
      },
      (error) => {
        console.error('Error fetching employees:', error);
        this.dotLoadingServices.setLoading(false);
      }
    );
  }

  showCreateEmployee = signal(false);

  onPageChange(pageNumber: number) {
    this.searchParams = {
      ...this.searchParams,
      pageNumber: pageNumber
    };
    this.search();
  }

  openCreateEmployee() {
    this.showCreateEmployee.set(true);
  }

  onShowModalChange(show: boolean) {
    this.showCreateEmployee.set(show);
  }

  clearSearch() {
    this.searchParams = {
      code: '',
      employeeStatus: '',
      pageNumber: 1,
      itemsPerPage: 10
    };
    const searchInput = document.getElementById('table-search') as HTMLInputElement;
    if (searchInput) {
      searchInput.value = '';
    }
    const statusSelect = document.getElementById('type') as HTMLSelectElement;
    if (statusSelect) {
      statusSelect.value = '';
    }
    this.search();
  }
}
